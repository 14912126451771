html.no-scroll{
  // para desactivar el scroll cuando el menu mobil esta activo, esta clase se añade con js
  overflow: hidden;
}

.custom-nav{
  --color-text-desktop: black;
  --color-text-mobile: white;
  background-color:white;
  width:100vw;
  position: fixed; top:0;
  z-index: 7;
  //padding:1rem;
  font-weight: 300;
  @include tablet{
    padding:0;
  }
  .bg-light{
    background-color: transparent;
  }
  &__container{
    display: flex;
    flex-flow: column;
    justify-content: space-between;

    @include desktop{
      flex-flow: column;
    }
    @include desktop{
      flex-flow: row;
      align-items: center;
      //padding:0 19rem;

    }

  }
  
}



// esto podria estar dentro de .custom-nav pero se recomienda no anidar a mas de tres niveles en sass
.main-nav{
  display: flex;
  flex-flow: column;
  align-items: center;
  position:fixed;
  left:-120vw;
    
  
  @include desktop{
    position: initial;
    flex-flow: row;
    justify-content: flex-end;
    
  }
  .item{
    color: black;
    padding:0 1rem;
    
  }
  .item-contrast{
    padding: .25rem 1rem;
    margin-top:-0.25rem;
    background-color: black;
    border-radius: 1rem;
    a{
      color: black;
    }
  }
  
}
.main-nav.is-active{
  position: initial;
  left:0;
  //min-height: 90vh;
  //margin-top:1rem;
  
  .item{
    margin-top: 1rem;
    margin-bottom: 1rem;
    color:black;
  }
  .item-contrast{
    margin-top:0;
    background-color: black;
    border-radius: .25rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
    margin-left:1rem;
    max-width: 12rem;
    text-align:center;
    a{
      color: black;
      font-size:1.2rem;
    }
  }
}
// para compensar la altura del menu fixed
.fix-top-padding{
  padding-top: 4.5rem;
  @include tablet{
    padding-top:10rem;
  }
}


nav{
	width: 100%;	
}

$burger-width:  1.7rem;
$burger-height: 2px;
$duration: 300ms;

.btn-burger {
  position: fixed;
  top:1.5rem; right: 1.5rem;
  display: flex;
  width: $burger-width;
  height: 1.3rem;
  cursor: pointer;
  flex-direction: column;
  justify-content: space-between;

  .pan,
  .carne {
    display: block;
    width: $burger-width;
    height: $burger-height;
    background-color: #1162D1;
    border-radius: $burger-height / 2;
  }

  .pan {
    transition: $duration transform ease-in;
    transform-origin: center;
  }

  .carne {
    transition: $duration ease-in-out;
    transition-property: transform, opacity;
  }

  .input {
    position: absolute;
    top: -1px;
    left: -1px;
    width: 1px;
    height: 1px;
    overflow: hidden;
    visibility: hidden;
  }
  @include desktop{
    display: none;
  }
  &.is-active{
    background-image: url(../images/cerrar.png);
    background-size: contain;
    background-repeat: no-repeat;
    width: 7%;
    height: 10%;

    .pan,.carne{
      background-color: transparent;
    }
    
    .pan:first-child {
      position: relative;
      transform: rotate(45deg) translate($burger-width / 4, $burger-width / 5);
    }
  
    .pan:last-child {
      position: relative;
      transform: rotate(-45deg) translate($burger-width / 4, -$burger-width / 5);
    }
  
    .carne {
      opacity: 0;
      transform: scale(0);
    }
    
  }
}


//clases para decorar los elementos cuando cambien a mobile

 .inicio-mobile{
  background-color: #F6317B;
  color: #fff;
  text-decoration: none;
  text-align: center;
  font-size: 2.2rem;

  @include desktop{
    background-color: #fff;
    font-size: 30px;
  }
}

.mision-mobile{
  background-color: #1162D1;
  color: #fff;
  text-decoration: none;
  text-align: center;
  font-size: 2.2rem;

  @include desktop{
    background-color: #fff;
    font-size: 30px;
  }
}

.beneficios-mobile{
   background-color: #7BDDE9;
   color: #fff;
   text-decoration: none;
   text-align: center;
   font-size: 2.2rem;

   @include desktop{
    background-color: #fff;
    font-size: 30px;
  }
}

.contacto-mobile{
  background-color:  #673297;
  color: #fff;
  text-decoration: none;
  text-align: center;
  font-size: 2.2rem;

  @include desktop{
    background-color: #fff;
    font-size: 30px;
  }
}

 //clase para desplegar el contacto
.desplegable:hover .links{
  display: inline;
  width: 500px;

  @include desktop{
    display: block;
  }
}

.social-links{
  position: fixed;
  top: 20rem;
  
  display: flex;
  flex-flow:row;
  justify-content: center;
  pointer-events: none;
  z-index:15;
  @include mobile{
    
    width: 100%;
  }
  @include tablet{
    width: 100%;
  }
  
  @include desktop{
    flex-flow: column;
    top:8rem;
    right:-10vw;
    transition: right .5s ease-in-out;
    
  }
  
  &.is-active{
    background-color: $color-morado;
    padding: 1rem 0;
    pointer-events: initial;
    @include mobile{
      left:0;
    }
    @include desktop{
      right: 1rem;
      transition: right .5s ease-in;
      background-color:transparent;
      width:6rem;
    }
    .social-link{
      animation-name: fadeIn;
      animation-duration: 2s;
      animation-iteration-count:1;
      animation-fill-mode: forwards;
    }
   
  }
  .social-link{
    opacity: 0;
    
    .social-icon{
      width: 4rem;
      @include desktop{
        width: 6rem;
      }
      
    }
  }
}
@for $i from 2 to 5 {
  .social-link:nth-child(#{$i}){
      animation-delay: ($i * 250ms);
  } 
}
@keyframes fadeIn{
  from{opacity:0}
  to{opacity: 1}
}



//clase para los botones de navegación
.botones-menu{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  background-color: transparent;
  border: transparent;
  color: var(--color-text-mobile);
  cursor: pointer;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 300;
  line-height: 30px;
  letter-spacing: 0.15em;
  font-size:23px;
  overflow: hidden;
  margin: 1rem;
  &__item{
    text-decoration: none;
    color: var(--color-text-mobile);
    text-align: center;
    font-weight: 300;
    width: 100%;
    height: 17vw;

    @include desktop{
      width: auto;
      padding: 2rem;
      color: var(--color-text-desktop);
      height: auto;
    }
  }
  @include desktop {
    font-size: 25px;
  }
}
.botones-menu__item.current{

  @include desktop{
    border-top:5px solid $color-rosa;
  }
}

.texto-menu{
  
  margin-top: 0.5rem;

  @include desktop{
    margin-top: 1.3rem;
  }
}
