.frases-wrapper{
    background-image: url('../images/beneficios-izq.svg');
    background-repeat: no-repeat;
    min-height: 50vh;
    padding-top:8rem;
    margin-top:-10rem;
    position: relative;
    z-index: 5;
    padding-left:1rem;
    padding-bottom:20rem;
    .quote{
        text-transform: uppercase;
        color: white;
        margin:2rem;
        font-size:2rem;
        font-weight: lighter;
        text-align: right;
    }
    .frases{
        display: flex;
        flex-flow: row;
        flex-wrap: wrap;
        @include mobile{
           
            overflow: hidden;
        }
        
    }

    // para evitar anidar a mas de tres niveles se confia en la cascada
    .frase{
        
        display: inline-flex;
        align-items: center;
        max-width: 13rem;
        font-size:1.5rem;
        opacity: 0;
        transition:opacity 1s ease, color 4s ease;

        width: 30%;
        @include mobile{
           
            font-size:.8rem;
        }
        &.visible{
            opacity:1;
            transition:opacity 1s ease, color .5s ease;
           
        }
        &.c-azul{
            color: $color-azul;
            //transition:opacity 1s ease, color 1s ease;
        }
        &.c-turquesa{
            color: $color-turquesa;
            transition:opacity 1s ease, color 1s ease;
        }
        &.c-rosa{
            color: $color-rosa;
            transition:opacity 1s ease, color 1s ease;
        }
        &.c-morado{
            color: $color-morado;
            transition:opacity 1s ease, color 1s ease;
        }
        &:before{
            content:'';
            background: url(../images/icn-arrow-right.svg);
            background-repeat: no-repeat;
            background-size: 1rem 1rem;
            background-position: center center;
            display: block;
            width: 1rem;
            height: 1rem;
            padding: 0.8rem;
        
        }
        p{
            padding:0.5rem;
            border-left:1px solid white;
            
        }
       .bottom{
        background-image:linear-gradient(to right,#7cc9db,#5da1c7) ;
        width: 100%;
        min-height: 10rem;
       }
    }
    
    
    // @for $i from 2 to 4 {
    //     .frases-col.ca>.frase:nth-child(#{$i}){
    //         animation-delay: ($i * 6s)-10s;
    //     }
       
    // }
    
    // @keyframes fadeinout{
    //     0%, 50%, 100%{ opacity:0}
    //     30%{ opacity:1}
        
    // }


}

