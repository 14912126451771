//Clases para la modal de mision

.modal {
  width: 100vw;
  height: 100vh;
  background-color: transparent;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;

  .modal-container {
    opacity: 0;
    visibility: hidden;

    z-index: 3;
    width: 97%;
    top: 5rem;

    background: #fff;
    display: flex;
    justify-content: start;
    align-items: start;
    border-radius: 50px;
    border: 1px solid transparent;
    box-shadow: 0 0 8px rgba(black, 0.25);
    margin-top: -230rem;
    @include tablet {
      width: 90%;


    }

    @include desktop {
      opacity: 0;
      visibility: hidden;
      position: fixed;
      z-index: 3;
      width: 70%;


      background: #fff;
      display: flex;
      justify-content: start;
      align-items: start;
      border-radius: 50px;
      border: 1px solid transparent;

      margin-top: -3rem;
    }
  }

  .modal-carta {
    font-size: 12px;
    text-align: left;
    margin-top: 0%;
    padding:2rem;

    @include desktop {
      font-size: 12px;
      text-align: left;
      margin-top: 0%;
      
    }
  }

  .modal-close {
    cursor: pointer;
    font-size: 15px;
    width: 3%;
    text-align: center;

  }

  .contenido-carta {
    font-size: .7rem;
    font-family: 'Cormorant Garamond', serif;
    font-weight: 500;
    

    @include tablet {
      font-size: 1.2rem;
      font-family: 'Cormorant Garamond', serif;
      font-weight: 500;
      
    }

    @include desktop {
      font-size: 1.2rem;
      font-family: 'Cormorant Garamond', serif;
      font-weight: 500;
      
    }
  }
}


.open-modal {
  text-decoration: none;
  color: var(--texto-rosa);
  margin-left: 8px;
  letter-spacing: 7px;
  font-size: 14px;

  @include desktop {
    margin-left: 1rem;
    font-size: 24px;
    letter-spacing: 10px;
  }
}






.icono-cerrar {
  width: 1.1rem;

  @include tablet {
    width: 100%;
  }

  @include desktop {
    width: 95%;
  }

}