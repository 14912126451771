
@import "mediaquerries";

//Elementos para hacer responsibe la imagen de ejemplo
.img {
    background-image: url(/images/foto_lety.png);
  }
  @media (min-width: 300px) {
    .img {
      background-image: url(/images/foto_lety.png);
    }
  }
  @media (min-width: 1500px) {
    .img {
      background-image: url(/images/foto_lety.png);
    }
  }

//Elementos para hacer responsive la imagen de ejemplo
  .img {
    background-image: url();
  }
  @media (min-width: 300px) {
    .img {
      background-image: url();
    }
  }
  @media (min-width: 1500px) {
    .img {
      background-image: url();
    }
  }

//Color para el fondo de la seccion hero-container
 
$color-home: #fff;


//Clase del hero para las imagenes
section.hero-container{
  background: $color-home;
  margin-top: -10rem;

  @include desktop{
    margin-top: auto;
  }
}

.hero-inicio{
  margin-top: auto;

  @include desktop {
    margin-top: 0rem;
  }
}


.hero{
   position: relative;
   width:99%;
   @include mobile{
    min-height: 70vh;
   }
   @include desktop{
    min-height: 550px;
   }
   
   
   &__content{
    position:absolute;
    z-index:5;
    @include mobile{
      margin-left:10%;
      width: 80%;
    }
    @include desktop{
      width:50%;
      right:0;
    }
   }
   &__background{
    position:absolute;
    height: 100%;
    
    @include desktop{
      width: 100%;
    }
    overflow: hidden;
    .image{ 
      object-fit: cover;
      @include mobile{
        margin-top:70%;
        width: 50%;
        margin-left:50%;
      }
      //object-position: right bottom;
      @include desktop{
        object-position: bottom center;
        position: absolute;
        bottom:0;
        left:5%;
        max-width:40%;
        max-height: 100%;
      }
      
    }
   }

}

.comilla1{
  width: 15%;
  height: 20%;
  max-width: 100%;
  @include desktop{
    width: 7%;
    height: 15%;
  }
}

.comilla2{
  width: 15%;
  height: 20%;
  max-width: 100%;
  @include desktop{
    width: 7%;
    height: 15%;
  }
}

.linea{
  width: 10%;
  height: 15%;
  max-width: 100%;
  @include desktop{
    width: 1.8rem;
    height:.50rem;
    margin-bottom: 1%;
  }
}
.whats-icon{
  width:1.6rem; 
  height: 1.6rem;
  position: relative;
  z-index: 2;
  top: 0.5rem;
  left: 0.5em;
  
  @include desktop{
    width: 11%;
    height: 2.6rem;
    position: relative;
    z-index: 2;
    top: 0.5rem;
    left: 3.2em;
  }
}

.imagen{ 
  background-color: #E6D7D2;
  border-radius: 43px;
  object-position: left center;
  opacity: .7;
  background-blend-mode: multiply; 
  object-fit: cover;
  width: 92%;
  height: 100%;
  position: inherit;
  margin-top: 15px;
  margin-left:5%;
  @include desktop{
    position: static;
    margin-top: 0px;
    margin-left: 46%;
    width: 85%;
    height: 100%;
  }
}
  
.icono{
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  width: 10%;
  height: 15%;
  margin-top: 0%;
  margin-left: 43%;
  @include desktop{
    margin-top: -45%;
    margin-left: 60%;
  }
}

.logoalegria{
  width: 27%;
  height: 82%;
  margin: 0;
  
  @include mobile{
    padding:0.5rem;
    
  }
  @include tablet{
    width: 10%;
    float: left;
    margin-top: 0rem;
  }
  @include desktop{
    width: 80%;
    float: left;
    margin-top: 0rem;
    margin-left: 2rem;
  }
}

.index-lety{
  width: 93%;
  margin-top: -242px;
  position: relative;
  margin-left: 1rem;

  @include tablet{
    width: 70%;
    height: 100%;
    margin-left: 9.5rem;
    position: relative;
    top:1rem;
  }

  @include desktop{
    width: 0%;
    height: 0%;
  }
}

.lety-image{
  width: 0%;
  height: 0%;

  @include desktop{
    width: 185%;
    height: 100%;
    margin-left: -7rem;
    margin-top: 9rem;
  }
}