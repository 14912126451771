$screen-sm-min: 576px;
$screen-md-min: 577px;
$screen-lg-min: 1025px;
$screen-uw-min: 1500px;


@mixin mobile {
  @media (max-width: #{$screen-sm-min}) {
      @content;
  }
}
@mixin tablet {
  @media (min-width: #{$screen-md-min}) {
      @content;
  }
}
@mixin desktop {
  @media (min-width: #{$screen-lg-min}) {
      @content;
  }
}
@mixin wide {
  @media (min-width: #{$screen-uw-min}) {
      @content;
  }
}
