
.placa-mision {
  width: 0%;

  @include desktop {
    margin-top: -50%;
    width: 100%;
  }
}

//clase para imagen de lety en primera sección

.lety-mision {
  width: 0%;
  height: 0%;

  @include desktop {
    width: 74%;
    height: 50%;
    margin-left: 4%;
  }
}
.fondo-mision {

  margin-left: 12rem;
  position: relative;
  top: -5rem;
  width: 50%;

  @include tablet {
    width: 30%;
    margin-left: 34rem;
    position: relative;
    top: -8rem;
  }

  @include desktop {
    width: 18vw;
    position: relative;
    top: 32.2rem;
    left: 32.3rem;
    margin-top: -521px;
  }

}


//Clase para el texto click
.texto-click{
  margin-left: .5rem;

  @include desktop{
    margin-left: 2rem;
  }
}

//clase para el recuadro de la informacion de lety torres
.mision {
  .info {
    z-index: 1;
    margin-top: -75rem;
    width: 100%;
    height: 1064px;
    position: relative;

    @include desktop {
      z-index: 1;
      margin-top: -50rem;
      width: 100%;
      height: 970px;

    }
  }
}
//clase para el apartado de texto en la parte inicial de mision
.mision-quote {
 
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  font-family: 'Lato', 'sans-serif';
  font-style: normal;
  font-weight: 300;
  color: var(--color-texto);
  text-align: right;
  font-size: 17px;
  line-height: 20px;
  letter-spacing: 0.11em;
  margin: 12%;
  margin-top: -9rem;

  @include desktop {
    line-height: 2.3rem;
    font-size: 1.9rem;
    margin: 14%;
    text-align: right;
    margin-top: 100px;
  }
}

.especialidades{
    background-image: url(../images/info.png);
    background-repeat: no-repeat;
    background-size: cover;

    margin-top:-8rem;
    //padding:5rem 2rem 20rem 2rem;
    color:white;
    z-index: 5;
    position:relative;
    padding:5rem 2rem 7rem 2rem;
    @include desktop{
      padding:5rem 2rem 20rem 2rem;
    }
    .section-title{
        font-size:2rem;
    }
    .especialidad{
        display: flex;
        justify-content: flex-start;
        flex-flow: column;
        align-items:center;
        margin:0; padding: 0;
        @include mobile{
            border-bottom:1px solid white;
        }
        @include desktop{
            flex-flow: row;

        }
        .titulo{
            
            width:100%;
            margin:1rem 0;
            font-size: 1rem;
            @include desktop{
                margin:0;
                text-align:right;
                padding-right:1rem;
                width:30%;
                flex-shrink: 0;
            }
        }
        .especialidad-descripcion{
            margin:0;
            padding:1rem 0 1rem 0;
            font-size:1.2rem;
            
            @include mobile{
              width:100%;
            }
            @include tablet{
              width: 100%;
            }
            @include desktop{
              border-left:1px solid white;
              padding:2rem  1rem 2rem 2rem;
            }
        }
    }
    
}


// Para elcontenido de mision
.mision {

  //clase para la imagen de lety de lado izquierdo
  .imagen-info {
    width: 0%;
    margin-left: 0%;
  
    @include desktop {
      width: 45%;
      margin-left: 14%;

    }
  }

  //Imagen de lety en mision lado derecho
  .lety {
    width: 79%;
    z-index: 1;

    @include tablet {
      width: 40%;
    }

    @include desktop {
      margin-left: .8rem;
      width: 59%;
      
    }
  }

  .sombra {
    width: 20%;
    margin-left: .5rem;
    margin-top: -7rem;
  }

  .bloque-carta {
    display: flex;
    flex-flow: column;
    align-items: center;
  }

  //Clase para la frase que va junto al boton e imagen de lety
  p.frase {
    text-align: center;
    font-size: 0px;
    margin-left: 0px;

    @include desktop {
      text-align: left;
      font-size: 29px;
      font-weight: 100;
      line-height: 3rem;
      margin: 2rem;

    }

  }

  //clase para el boton de la carta
  .boton-carta {
    border: 1px solid lime;
    margin-left: 0.5rem;
    background: white;
    border-radius: 33px;
    box-shadow: 0 0 9px rgba(black, 0.25);
    letter-spacing: 0.1rem;
    font-size: 14px;
    font-weight: normal;
    line-height: 18px;

    z-index: 2;

    @include desktop {
      border: 1px solid lime;
      margin-left: 2.2rem;
      background: white;
      border-radius: 33px;
      letter-spacing: 0.5rem;
      font-size: 12px;
      font-weight: normal;
      line-height: 25px;
      width: 62%;
    }
  }
}

