:root {
  --color-info: #fff;
  --color-carta: white;
  --color-texto: black;
  --max-width: 1500px;
  --extended-width: 1500px;
  --texto-morado: #673297;
  --texto-rosa: #F6317B;
  --texto-azul: #7BDDE9;
  --texto-turquesa: #1162D1;
}

$max-width: 1500px;
$extended-width: 2000px;
$header-height: 88px;


$color-primario: #fff;
$color-morado: var(--texto-morado);
$color-rosa: var(--texto-rosa);
$color-azul: var(--texto-azul);
$color-turquesa: var(--texto-turquesa);
$azul-rey: #1062D1;


//Hojas para la descripción del escritorio
@import "https://fonts.googleapis.com/css2?family=Lato:wght@300;700;900&display=swap";
@import "https://fonts.googleapis.com/css2?family=Cormorant+Garamond:wght@500&display=swap";

@import "media";
@import "mediaquerries";
@import "navbar";


@import "frases";
@import "footer";

html {
  scroll-behavior: smooth;
}

body {
  padding: 196px 0 0 0;
  margin: 0;
  overflow-x: hidden;
  background-color: $color-primario;
  font-family: "Lato";

  @include desktop {
    overflow-x: hidden;
    padding: $header-height 0 0 0;
    margin: 0;
    background-color: $color-primario;
  }
}

.color-rosa{color: $color-rosa}
.color-morado{color:$color-morado}
.azul-rey{color: $azul-rey}
// contenedor que da un max width
.container {

  max-width: var(--max-width);
  margin-left: auto;
  margin-right: auto;
}

.container-extended {
  max-width: var(--extended-width);
  margin-left: auto;
  margin-right: auto;
}

//Apartado del menu
.menu {
  background-color: $color-primario;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 10;
}

.max-width {
  @include desktop {
    max-width: 1500px;
    margin: auto;
  }
}


//clase para la sección de inicio y dar ajuste tanto para escritorio como movil
.inicio {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  align-content: flex-end;
  flex-direction: column-reverse;
  //background-color:aqua;

  min-height: 88.5vh;
  @include desktop {
    flex-direction: row;
  }

  .column {
    width: 100%;

    @include desktop {
      width: 50%;
    }
  }
  .column.hometext{
    
    padding-bottom:12rem;
    @include desktop{
      align-self: center !important;
    }
  }
  
}

//clase para el fondo en mobile de index

.fondo-index {
  width: 33%;
  margin-top: -103rem;
  margin-left: 17rem;

  @include tablet {
    width: 28%;
    margin-top: -40rem;
    margin-left: 35rem;
  }

  @include desktop {
    width: 0;
    overflow: hidden;
    opacity: 0;
  }
}

.navegacion {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  height: 20%;

  //elementos para darle un espacio y estructura a los elementos 
  .columna {

    //aqui se planeo esta columna para el icono que se vio en el figma pero despues lo quitamos
    &.w-primero {
      width: 100%;

      @include desktop {
        width: 50%;
      }
    }

    &.w-segundo {
      width: 100%;

      @include desktop {
        width: 50%;
      }
    }
  }
}

//clases para el color de cada texto

.morado {
  color: var(--texto-morado);
}

.rosa {
  color: var(--texto-rosa);
}

.azul {
  color: var(--texto-azul);
}

.turquesa {
  color: var(--texto-turquesa);
}

//clase para la información
// TODO: cual informacion?
.informacion {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  align-content: space-between;

  .espacio {
    &.izquierdo {
      width: 100%;

      @include desktop {
        width: 50%;
        //border-right: 1px solid #fff;
      }
    }

    &.derecho {
      width: 100%;

      @include desktop {
        width: 50%;
      }
    }
  }
}

.boton-menu {
  cursor: pointer;
  margin-left: 25rem;
  margin-top: 0rem;
  position: relative;
  top: 3.8rem;
  width: 10%;

  @include desktop {
    width: 0%;
    overflow: hidden;
  }
}

/* Aqui esta todo el estilo para el apartado de mision

*/

//Clase para la estructura de mision
.mision {

  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  height: 20%;
  flex-direction: column-reverse;
  margin-top: -16rem;

  @include desktop {


    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    flex-direction: row;
    margin-top: auto;
  }

  //elementos para darle un espacio y estructura a los elementos 
  .columna {

    //aqui se planeo esta columna para el icono que se vio en el figma pero despues lo quitamos
    &.w-primero {

      width: 100%;

      @include desktop {
        width: 50%;
      }
    }

    &.w-segundo {

      background-image: url('../images/forma-caprichosa.svg');
      background-repeat: no-repeat;
      background-size: contain;
      background-position: right;

      width: 100%;

      @include desktop {
        width: 50%;
        padding: 6rem 0 7rem 0;
      }
    }
  }




}






@import "mision";
@import "modal";







//Clase para el contenido de la tercera section
// TODO: clase demasiado generica
.contenido {
  background-color: $color-primario;
}




//Composición para el texto que describe el contenido
.texto {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  font-family: 'Lato', 'sans-serif';
  font-style: normal;
  font-weight: 300;
  color: var(--color-texto);
  text-align: left;
  font-size: 24px;
  line-height: 40px;
  letter-spacing: 0.11em;
  margin: 12%;
  margin-top: -4rem;

  @include desktop {
    line-height: 2.8rem;
    font-size: 2.4rem;
    margin: 14%;
    text-align: right;
    margin-top: 100px;
  }
}

//Estructura y personalización para el nombre que esta despues del menu de navegación
.nombre {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  font-family: 'Lato', 'sans-serif';
  font-style: normal;
  color: var(--color-texto);
  font-weight: 300;
  text-align: right;
  font-size: 25px;
  line-height: 27px;
  letter-spacing: 0.15em;
  margin: 4%;

  @include desktop {
    font-size: 2.7rem;
    line-height: 2rem;
    text-align: right;
    margin: 0%;
    margin-left: 15rem;
  }
}

//clase para el tamaño del cargo en la seccion de index en la parte "Coach espiritual"
.cargo{

  font-size: 25px;
  color: var(--texto-morado);

  @include desktop{
   font-size: 1.6rem;
   margin-top: -1rem;
   margin-left: 2rem;
  }
}

/* Clases para el apartado de beneficios */

.beneficios {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  height: 20%;
  //elementos para darle un espacio y estructura a los elementos 
  .columna {

    //aqui se planeo esta columna para el icono que se vio en el figma pero despues lo quitamos
    &.w-primero {
      width: 100%;

      @include desktop {
        width: 50%;
        padding-bottom:9rem;
      }
    }

    &.w-segundo {
      width: 100%;

      @include desktop {
        width: 50%;
      }
    }
  }
}

//texto-beneficios clase para el apartado de texto en la seccion beneficios
.texto-beneficios {
  text-align: left;
  font-size: 28px;
  font-family: 'Lato';
  font-weight: 100;
  letter-spacing: 3px;
  margin-left: 8%;
  //margin-top: 100px;

  @include tablet {
    font-size: 52px;
  }

  @include desktop {
    text-align: left;
    font-size: 47px;
    font-family: 'Lato';
    font-weight: 100;
    letter-spacing: 14px;
    margin-top: 6rem;
    margin-left: 1.9rem;
  }
}

//Clase para los parrafos de beneficios
.parrafos-bene {
  font-size: 15px;
  margin: 2px 31px 4px 39px;
  font-family: Lato;
  font-weight: 100;
  line-height: 30px;
  letter-spacing: 0.1rem;

  @include tablet {
    margin: 12px 43px 28px 38px;
    line-height: 41px;
    font-size: 24px;
  }

  @include desktop {
    font-size: 25px;
    margin: 1rem 6rem 2rem 2rem;
    font-family: Lato;
    font-weight: 100;
    line-height: 30px;
    letter-spacing: 0.1rem;
  }
}

.parrafo {
  font-size: 15px;
  margin: 46px 52px 42px 40px;
  font-family: Lato;
  font-weight: 100;
  line-height: 30px;
  letter-spacing: 0.1rem;

  @include tablet {
    font-size: 24px;
    margin: -12px 66px 84px 40px;
    line-height: 41px;
  }

  @include desktop {
    font-size: 25px;
    margin: -12px 291px 84px 40px;
    font-family: Lato;
    font-weight: 100;
    line-height: 30px;
    letter-spacing: 0.1rem;
  }
}

//clase para la imagen de lety-beneficios
.lety-beneficios {
  width: 64%;
  margin-left: 11%;
  margin-top: 2rem;
}

//Clase para la parte de la animación
.fondo-bene {
  width: 100%;
  margin-top: -14%;
}



a.anchor {
  display: block;
  position: relative;
  top: -$header-height;
  visibility: hidden;
}