.footer{
    margin-top:-4rem;
    z-index: 9;
    @include mobile{
        position: absolute;
    }

    @include tablet{
        margin-top: -7rem;
        position: relative;
    }

    @include desktop{

       margin-top: -9.4rem;

    }
    .wave{
        width: 100%;
        margin:0; padding:0;
        
    }
    .foot{
        background-color: #E72574;
        color: white;
        margin-top:-1rem;
        display: flex;
        flex-flow: column;
        align-items: center;
        padding-bottom:5rem;
        @include mobile{
            padding:2rem 1rem 4rem 1rem;
        }
        @include tablet{
            flex-flow: row;
            justify-content: space-between;
            align-items: flex-end;
            align-content: center;
        }

        @include desktop{
            margin-top: -11rem;
        }
   
        a{
            color: white;
            text-decoration: none;
            border-bottom:0  solid white;
            transition: 0.5s border-width ease-in;
        }
        a:hover{
            border-bottom:3px solid white;
            transition: 0.5s border-width ease-in;
        }
        .ico-whats{
            height: 1.5rem;
            margin-left: 1rem;

            @include desktop{
                height: 2rem;
                margin-left: -2rem;
            }
        }

        .colibri {
            width: 30%;
            margin-left: 1rem;
            text-align: center;

            @include desktop{
                width: 35%;
                margin-left: -0.5rem;
                position: relative;
                top: 2rem;
            }
        }

        .text-footer{
            font-size: 25px;

            @include desktop{
                font-size: 30px;
            }
        }

        .correo{
            margin-left: 1rem;

            @include desktop{
                margin-left: -2rem;
            }
        }

        .columna{

            &.telefono{
              width: 100%;
              order: 2;
              text-align: center;

              @include tablet{
                width: 33%;
                order: 1;
              }

               @include desktop{
                  width: 33%;
                  order: 1;
               }

            }

            &.colibri{
                width: 100%;
                order: 1;

                @include tablet{
                    width: 33%;
                    order: 2;
                  }
  
                @include desktop{
                    width: 33%;
                    order: 2;
                }
  
            }

            &.correo{
                width: 100%;
                order: 3;
                text-align: center;


                @include tablet{
                    width: 33%;
                    order: 3;
                }
  
                 @include desktop{
                    width: 33%;
                    order: 3;
                 }
  
              }
        }
    }
    &.ft-beneficios{
        @include mobile{
            margin-top:-16rem;
            // .wave{
            //     background-image:linear-gradient(#7cc9db,#5da1c7);
            // }
        }
        @include tablet{
            margin-top:-14rem;
        }

        @include desktop{
            margin-top: -15rem;
        }
    }


    &.ft-mision{
        @include mobile{
            margin-top: -5rem;
        }

        @include tablet{
            margin-top: -7rem;
        }

        @include desktop{
            margin-top: -14rem;
        }
    }
}